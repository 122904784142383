<template>
  <scoped-job-list title="Applied Jobs" scope="applied" past-tense-action="applied to" />
</template>

<script>
import ScopedJobList from '~/components/Jobs/Freelancer/ScopedList'

export default {
  components: { ScopedJobList },
}
</script>
